<template>
    <div  :class="`noty_${item.id}`" class="noty_link overflow-hidden ellipsis" v-html="item.message"></div> 
</template>

<script>
export default {
    name: "NotificationMessage",
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        open(item){
            const ell = item.target.attributes
                       
            const name = ell[1].value,
                query = ell[2].textContent,
                open = ell[3] ? ell[3].value : null

            const jsonStr = query.replace(/(\w+:)|(\w+ :)/g, function(matchedStr) {
                return '"' + matchedStr.substring(0, matchedStr.length - 1) + '":';
            })

            const obj = JSON.parse(jsonStr); 
            this.$emit('read')

            if(name === 'full_invest_project_info') {
                open === "true" ? this.$store.commit('notifications/SET_DRAWER_Z_INDEX') : this.$store.commit('notifications/SET_DRAWER_VISIBLE', false)
                this.$router.push({ name: name, params: { id: obj.id } }).catch(e=>{})
                return
            }

            if(open === null || open === "false") {
                this.$store.commit('notifications/SET_DRAWER_VISIBLE', false)
                this.$router.push({name, query: obj}).catch(e=>{})
            } else {
                this.$store.commit('notifications/SET_DRAWER_Z_INDEX')
                this.$router.push({ query: obj}).catch(e=>{})
            }
        },
        setListener(){
            this.$nextTick(()=>{
                try{ 
                    const links = document.querySelectorAll(`.noty_${this.item.id} .n_link`)
                    if(links?.length){
                        links.forEach(el=>{
                            el.addEventListener('click', (item)=> this.open(item))
                          
                        })
                    }
                }
                catch(e){
                    console.error("Ошибка открытия. Проверьте backend!" + e)
                }
            })
        },
       
    },
    updated(){
        this.setListener()
    
    },
    mounted(){
        this.setListener()
    }
}
</script>

<style lang="scss">
.noty_link{
  .n_link{
    color: var(--primaryColor);
  }
  cursor: pointer;
}
</style>

<style scoped>
.ellipsis {
    text-overflow: ellipsis;
}
</style>