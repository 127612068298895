<template>
    <div ref="notifyDrawerWrapper">
        <div v-if="!isMobile" class="header_noty flex items-center justify-between flex-wrap"
             :class="{'header_noty_mobile': isMobile}">  
            <div class="flex items-center mr-4 mb-2">
                <a-button 
                    @click="readAllNoty()" 
                    type="primary" 
                    size="large" 
                    class="mr-2">
                    {{ $t('noty.readAll') }}
                </a-button>
                <PageFilter 
                    :excludeFields="excludeFilters"
                    :model="model" 
                    :title="$t('filters')"
                    :getPopupContainer="getPopupContainer"
                    filterButtonSize="large"
                    size="large"
                    :page_name ="page_name"/>         
            </div>
            <a-popover
                v-if="tgBotLink"
                :mouseLeaveDelay="0.5"
                v-model="popupVisible"
                :getPopupContainer="getPopupContainer"
                placement="bottom">
                <a-button 
                    type="ui" 
                    ghost
                    size="large" 
                    class="flex items-center">
                    <a     
                        ref="telegramLink" 
                        :href="tgBotLink"
                        class="flex items-center"
                        target="_blank">
                        <span class="h-4 w-4 mr-3">
                            <img class="h-full" src="@/assets/images/telegram.svg" />
                        </span>
                        {{ $t('noty.telegramNotifications') }}
                    </a>
                </a-button>
                <template v-slot:content>
                    <qr-code :size="150" :text="tgBotLink" />
                </template>
            </a-popover>
        </div>
        <template v-else>
            <div v-if="tgBotLink" class="mb-3 mt-2">
                <a-button 
                    type="ui" 
                    class="flex items-center">
                    <a     
                        :href="tgBotLink"
                        class="flex items-center"
                        target="_blank">
                        <span class="h-4 w-4 mr-3">
                            <img class="h-full" src="@/assets/images/telegram.svg" />
                        </span>
                        {{ $t('noty.telegramNotifications') }}
                    </a>
                </a-button>
            </div>
        </template>
        <a-list 
            class="mt-1" 
            :size="isMobile ? 'small' : 'large'"  
            :data-source="list">
            <a-list-item 
                slot="renderItem" 
                slot-scope="item">
                <ListItem 
                    :item="item" 
                    @read="readNoty(item)" />
            </a-list-item>
            <a-spin 
                class="mt-4"  
                v-show="loadingT && !loading" />
            <a-empty 
                v-if="!loading && !loadingT && list.length === 0" 
                class="mt-4" 
                :description="$t('noty.emptyList')" />
            <infinite-loading
                ref="notify_infinity"
                v-bind:distance="10"
                @infinite="scrollHandler">
                <div slot="spinner">
                    <a-spin 
                        class="mt-4"  
                        v-show="loading" />
                </div>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
            </infinite-loading>
        </a-list>

        <div v-if="isMobile" class="float_add">
            <div class="filter_slot">
                <PageFilter 
                    :excludeFields="excludeFilters"
                    :model="model" 
                    :title="$t('noty.filters')"
                    filterButtonSize="large"
                    :zIndex="6000"
                    size="large"
                    :page_name ="page_name"/>  
            </div>
            <a-button 
                flaticon
                shape="circle"
                size="large"
                type="primary"
                icon="fi-rr-check-double"
                @click="readAllNoty()" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading'
import eventBus from '@/utils/eventBus'
import PageFilter from '@/components/PageFilter'
import VueQRCodeComponent from 'vue-qrcode-component'
import ListItem from './ListItem.vue'
export default {
    name: "NotificationsList",
    components: {
        InfiniteLoading, 
        PageFilter, 
        ListItem,
        QrCode: VueQRCodeComponent
    },
    data() {
        return {
            excludeFilters: [],
            model: "notifications.WebNotificationModel",
            page_name: "page_list_notifications.WebNotificationModel",
            loading: false,
            switch: false,
            loadingT: false,
            page: 0,
            popupVisible: false,
        }
    },
    computed: {
        ...mapGetters({
            list: "notifications/getListNoty",
        }),
        isMobile() {
            return this.$store.state.isMobile
        },
        next() {
            return this.$store.state.notifications.next
        },
        user() {
            return this.$store.state.user.user
        },
        tgBotURL() {
            return this.$store?.state?.config?.config?.tg_bot_settings?.url
        },
        tgBotLink() {
            if(this.user) {
                const telegramBotLink = `${this.tgBotURL}?start=${this.user.telegram_connect_token}`
                return telegramBotLink
            }
            return null
        }
    },
    methods: {
        ...mapActions({
            getListNoty: "notifications/getListNoty",
            readNotyy: "notifications/readNoty",
            readAllNoty: "notifications/readAllNoty",
        }),
        ...mapMutations({
            clearList: "notifications/clearList"
        }),
        getPopupContainer() {
            return this.$refs['notifyDrawerWrapper']
        },
        readNoty(item){
            if(!item.is_read)
                this.readNotyy(item.id)
        },
        async scrollHandler($state = null) {
            if(!this.loading && this.next) {
                try {
                    this.loading = true
                    const res = await this.getListNoty({page_name: this.page_name})
                    if(!res.next) {
                        if($state)
                            $state.complete()
                      
                    } else {
                        if($state)
                            $state.loaded()
                    }
                } catch(e) {
                    console.error(e)
                } finally {
                    this.loading = false
                }
            } else {
                if($state)
                    $state.complete()
            }
        },
        reload() {
            this.$nextTick(() => {
                this.$store.commit('notifications/SET_PAGE', 0)
                this.$store.commit('notifications/SET_NEXT', true)
                this.clearList()
                if(this.$refs['notify_infinity']?.stateChanger)
                    this.$refs['notify_infinity'].stateChanger.reset()
            })
        },
        changeSwitch(val) {
            this.page = 0
        }
    },
    mounted() {
        if(this.isMobile) {
            window.addEventListener("focus", () => {
                this.reload()
            })
        }
        eventBus.$on(`update_filter_${this.model}`, ()=>{
            this.reload()
        })
    },
    beforeDestroy() {
        eventBus.$off(`update_filter_${this.model}`)
    }
}
</script>

<style lang="scss">
.header_noty{
    position: sticky;
    z-index: 30;
    top: 0;

    background: var(--eBg);
    padding-bottom: 10px;
    padding-top: 0.5rem;
    
}
.header_noty_mobile {
    top: var(--headerHeight);
}
.title {
    font-weight: 300;
    font-size: 24px;
}

.mob-text-lg {
    font-size: 1.4rem;
    line-height: 1.5;
}
</style>

<style lang="scss" scoped>
.notify_drawer{
    &::v-deep{
        .ant-drawer-header-no-title{
            display: none;
        }
        .ant-drawer-body{
            overflow: hidden;
            padding: 0px;
            height: 100%;
        }
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
        }
        .drawer_header{
            border-bottom: 1px solid var(--border2);
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 15px;
            padding-right: 15px;
            .drawer_title{
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
        .drawer_body{
            height: calc(100% - 50px);
            overflow-y: auto;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 15px;
        }
    }
}
</style>