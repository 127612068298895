<template>
    <a-drawer
        title=""
        placement="right"
        :zIndex="drawerZIndex"
        :width="drawerWidth"
        class="notify_drawer"
        destroyOnClose
        :visible="visible"
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <div class="drawer_header">
            <div class="drawer_title">{{ $t('noty.notificationList') }}</div>
            <div class="drawer_header__right">
                <a-button 
                    flaticon 
                    type="ui" 
                    ghost 
                    shape="circle"
                    icon="fi-rr-cross"
                    @click="visible = false" />
            </div>
        </div>
        <div class="drawer_body">
            <List />
        </div>
    </a-drawer>
</template>

<script>
import List from './List.vue'
export default {
    name: "NotificationsList",
    components: {
        List
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        drawerZIndex() {
            return this.$store.state.notifications.drawerZIndex
        },
        drawerWidth() {
            if(this.windowWidth > 1200 && !this.isMobile)
                return 1200
            else {
                return '100%'
            }
        },
        visible: {
            get() {
                return this.$store.state.notifications.visible
            },
            set(val) {
                this.$store.commit('notifications/SET_DRAWER_VISIBLE', val)
            }
        }
    },
    methods: {
        afterVisibleChange(vis) {
            if(vis) {
                this.$store.commit('notifications/SET_DRAWER_Z_INDEX', 5000)
                this.$notification.destroy()
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.notify_drawer{
    &::v-deep{
        .ant-drawer-header-no-title{
            display: none;
        }
        .ant-drawer-body{
            overflow: hidden;
            padding: 0px;
            height: 100%;
        }
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
        }
        .drawer_header{
            border-bottom: 1px solid var(--border2);
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 15px;
            padding-right: 15px;
            .drawer_title{
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
        .drawer_body{
            height: calc(100% - 40px);
            overflow-y: auto;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 15px;
        }
    }
}
</style>